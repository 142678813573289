import images from './images';

const wines = [
  {
    title: 'Edo Roll',
    price: '13,50€',
    tags: 'mit Lachs, Avocado, paniertem Lauch, Firschkäse, Masago, umhüllt mit Garnelen a)c)b)f)g)',
  },
  {
    title: 'Dragon Roll',
    price: '12,00€',
    tags: 'mit Garnelen, Spargel, Frischkäse, Seam, umhüllt von Avocado b)g)a)',
  },
  {
    title: 'Rainbow Roll',
    price: '13,00€',
    tags: 'mit Garnelen, Avocado, panierten Lauch, umhüllt von Lachs, Thunfisch, Unagi und Masago b)f)g)a)',
  },
  {
    title: 'Tuna Deluxe',
    price: '13,00€',
    tags: 'mit Lachs, Avocado, paniertem Lauch,umhüllt von Thunfisch a)c)f)',
  },
  {
    title: 'Popeye Roll',
    price: '11,00€',
    tags: 'panierter Spargel, Avocado, Frischkäse, Paprika, umhüllt von Spinat a)c)g)',
  },
    {
    title: 'Und vieles mehr',
    price: '    .     .   .   .  . .',
  },
];

const cocktails = [
  {
    title: 'Alaska Roll',
    price: '13,50€',
    tags: 'mit Garnelen, Avocado, Frischkäse, Rettichstreifen, Lauch b)g)',
  },
  {
    title: 'Ocean Bay',
    price: '14,50€',
    tags: 'mit Garnelen, Avocado, Röstzwiebeln, Masago, Mozarella, umhüllt mit Jakobsmuscheln a)c)b)f)g)n)',
  },
  {
    title: 'Mango Roll',
    price: '11,00€',
    tags: 'mit Lachs, Avocado, Frischkäse, umhüllt mit Mango a)c)f)g)',
  },
  {
    title: 'Jolina Roll',
    price: '11,50€',
    tags: 'Avocado, panierter Lauch, Paprika, Frischkäse, umhüllt mit einem Lachsmantel a)c)f)g)',
  },
  {
    title: 'Mr. Mrs',
    price: '13,50€',
    tags: 'gefüllt mit panierten Garnelen, Avocado, Spargel, Rucola, Frischkäse und Spezialsauce',
  },
  {
    title: 'Und vieles mehr',
    price: '    .     .   .   .  . .',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards };
