import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">


    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Über uns</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Wir verwenden nur beste Zutaten, von frischem Fisch bis hin zu handverlesenem Gemüse und Kräutern. Ob Sie nun ein Sushi-Kenner sind oder uns zum ersten Mal besuchen, unsere Karte hat für jeden etwas zu bieten. Von klassischen Sushi Rolls bis hin zu Spezialitäten des Hauses ist für jeden etwas dabei. Und wenn Sushi nicht Ihr Ding ist, haben wir auch andere Optionen - von Pad Thai und Bowls bis hin zu Udon Nudeln.</p>
        <button type="button" className="custom__button"><a href='https://www.google.com/maps/place/The+best+Sushi/@50.9640117,6.899565,17z/data=!3m1!4b1!4m6!3m5!1s0x47bf25635d32cb53:0x364098d9b79de5ed!8m2!3d50.9640117!4d6.899565!16s%2Fg%2F11s9vz086s'>Rezensionen</a></button>
      </div>
      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Unser Lokal</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Wir glauben, dass Sushi mehr ist als nur ein Essen - es ist ein Erlebnis. Deshalb haben wir eine warme, einladende Atmosphäre geschaffen, die sich perfekt für ein schnelles Mittagessen, ein Abendessen zu zweit oder für die ganze Familie eignet. Unser freundliches Personal steht Ihnen jederzeit zur Verfügung, um Fragen zu beantworten, Empfehlungen auszusprechen und dafür zu sorgen, dass Ihr Besuch zu einer tollen Erfahrung wird.</p>
        <a href='https://www.google.com/local/place/fid/0x47bf25635d32cb53:0x364098d9b79de5ed/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipMDsrPvAxMRoX6EidZD7d7opP_0CDgfS2dlEzCa%3Dw160-h106-k-no-pi-0-ya193.4-ro0-fo100&ik=CAoSLEFGMVFpcE1Ec3JQdkF4TVJvWDZFaWRaRDdkN29wUF8wQ0RnZlMyZGxFekNh'>
        <button type="button" className="custom__button">3D Rundgang</button>
        </a>
      </div>
    </div>
  </div>
);

export default AboutUs;