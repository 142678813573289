import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      {/*<SubHeading title="Wort des Kochs" /*/}
      <h1 className="headtext__cormorant">Unsere Philosophie</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">Sushi zu machen scheint auf dem ersten Blick recht simpel. </p>
        </div>
        <p className="p__opensans"> Es braucht nur Reis und Fisch. Aber es ist nicht einfach, die Zubereitung zu perfektionieren. Bei gutem Sushi geht es um Ausgewogenheit. Man muss die richtige Kombination von Aromen, Texturen und Farben finden." </p>
      </div>

      <div className="app__chef-sign">
        <img src={images.sign} alt="sign_image" />
        <p className="p__opensans">Sushi Koch & Betreiber</p>
      </div>
    </div>
  </div>
);

export default Chef;