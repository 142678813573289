import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Authentisch japanische Küche in Köln Bickendorf" />
      <h1 className="app__header-h1">  Willkommen bei "The best Sushi"</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>Frisch, gesund und lecker lautet die Devise in unserem Sushi Restaurant in Köln Bickendorf. Zutaten von höchster Qualität kombiniert mit einer sorgfältigen Zubereitung versprechen Geschmack, Authentizität und Genuss unserer asiatischen Spezialitäten. Ob als Nigiri oder Maki, als Inside Out oder Sashimi, ob traditionell oder Fusion: Herzlich willkommen bei "The best Sushi"! </p>
      <a href="https://drive.google.com/file/d/1oQNcOwH98pyctwGW_MfbqQtVN20asoEr/view?usp=sharing">
        <button type="button" className="custom__button">Speisekarte</button>
      </a>
    </div>
    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;
