import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
       {/*<SubHeading title="Eine Auswahl der beliebtesten Gerichte" />*/}
      <h1 className="headtext__cormorant">Auswahl unserer Special Rolls</h1>  
    </div>
    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Sushi Special Rolls (8Stk.)</p>
        <div className="app__specialMenu_menu_items">
          {data.wines.map((wine, index) => (
            <MenuItem key={wine.title + index} title={wine.title} price={wine.price} tags={wine.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Sushi Special Rolls (8Stk.)</p>
        <div className="app__specialMenu_menu_items">
          {data.cocktails.map((cocktail, index) => (
            <MenuItem key={cocktail.title + index} title={cocktail.title} price={cocktail.price} tags={cocktail.tags} />
          ))}
        </div>
      </div>
    </div>

    <div style={{ marginTop: 15 }}>

    <a href="https://drive.google.com/file/d/1oQNcOwH98pyctwGW_MfbqQtVN20asoEr/view?usp=sharing">
        <button type="button" className="custom__button">Speisekarte</button>
      </a>
    </div>
  </div>
);

export default SpecialMenu;
